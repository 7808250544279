.gallery-container {
  width: 70vw;
  height: auto;
  margin: 0 auto;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  > .swiper-container {
    background-color: #000;
  }
  .swiper-slide {
    overflow: hidden;
    > .gallery-full-image {
      width: auto;
      height: 80vh;
    }
  }

  .swiper-container-thumbs {
    img {
      width: 13vw;
      height: 18vh;
      opacity: 0.5;
      transition: all 0.2s ease-in;
    }

    .swiper-slide-thumb-active {
      // Styles for active thumb slide
      > img {
        opacity: 1 !important;
        border-radius: 20%;
      }
    }
  }
}

@media only screen and(max-width : 768px) {
  .gallery-container {
    width: 100vw;
    min-height: 60vh;
    margin-top: 0;

    .swiper-slide {
      > .gallery-full-image {
        width: auto !important;
        height: 60vh !important;
      }
    }

    .swiper-container-thumbs {
      img {
        width: 100vw/5;
        height: 8vh;
      }
    }
  }
}
