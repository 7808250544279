.grid-gallery-container {
  padding: 2rem 0;
  width: 70%;
  height: 100%;
  margin: 1rem auto;
  display: grid;
  gap: 0.2rem;
  background: white;
  padding: 0.7rem;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.3);
  justify-content: center;
  grid-template-columns: repeat(auto-fill, 150px);
  grid-auto-rows: 100px;
  grid-auto-flow: dense;

  .item {
    width: 100%;
    height: 100%;
    background: green;
    img {
      width: 100%;
      height: auto;
    }
  }
  .item.v1 {
    grid-row: span 1;
  }
  .item.v2 {
    grid-row: span 2;
  }
  .item.v3 {
    grid-row: span 3;
  }
  .item.v4 {
    grid-row: span 4;
  }
  .item.v5 {
    grid-row: span 5;
  }
  .item.v6 {
    grid-row: span 6;
  }

  .item.h1 {
    grid-column: span 1;
  }
  .item.h2 {
    grid-column: span 2;
  }
  .item.h3 {
    grid-column: span 3;
  }
  .item.h4 {
    grid-column: span 4;
  }
  .item.h5 {
    grid-column: span 5;
  }
  .item.h6 {
    grid-column: span 6;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}
.fullImage {
  width: auto;
  height: 80vh;
}

@media screen and (max-width: 500px) {
  .grid-gallery-container {
    grid-template-columns: repeat(auto-fit, 75px);
    grid-auto-rows: 50;
    width: 95%;
    padding: 0;
    margin: 0.5rem auto;
  }
  .fullImage {
    width: 80vw;
    height: auto;
  }
}
