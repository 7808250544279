$primary: #5a319b;
$secondary: #d5098e;

.inquiry-container {
  display: flex;
  justify-content: center;
}
.inquiry-paper {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
  padding: 1rem;
  > form {
    display: flex;
    flex-direction: column;
    min-width: 30vw;
  }
}

@media screen and (max-width: 880px) {
  .inquiry-paper {
    width: 100%;
    margin: 1rem 0;
    padding: 1rem 0.2rem;
  }
}
