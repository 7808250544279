.hero-slider {
  .swiper-pagination {
    top: 90% !important;
  }
}
@media screen and(max-width:768px) {
  .hero-slider img {
    height: 40vh !important;
  }
}
