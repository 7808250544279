$primary: #5a319b;
$secondary: #d5098e;
@import url('https://fonts.googleapis.com/css2?family=Grenze+Gotisch:wght@200;400&display=swap');

.page-heading {
  font-weight: 300 !important;
  color: #eee;
  background-color: #5a319b;
  font-family: 'Grenze Gotisch', cursive;
  width: 70%;
  position: relative;
  margin-left: -2.2rem;
  padding: 0.6rem;
  padding-left: 2rem;
  align-self: start;
  &::before {
    content: ' ';
    position: absolute;
    width: 30px;
    height: 0;
    left: -40px;
    top: 20px;

    border-width: 21px 15px;
    border-style: solid;
    border-color: $primary $primary $primary transparent;
  }
  &::after {
    content: ' ';
    position: absolute;
    left: 0px;
    top: 100%;
    border-width: 5px 10px;
    border-style: solid;
    border-color: $secondary $secondary transparent transparent;
  }
}

@media only screen and (max-width: 768px) {
  .page-heading {
    padding-left: 2rem;
    margin-left: 0px !important;
    width: 100%;
    &::before,
    &::after {
      display: none;
    }
  }
}
