$primary: #5a319b;
$secondary: #d5098e;

.school-lab-container {
  display: flex;
  justify-content: center;
  min-height: 72vh;
}
.school-lab {
  position: relative;
  top: 2rem;
  width: 60vw;
  height: auto;
  padding: 2rem;
  line-height: 2rem;
  margin-bottom: 5rem;
  .lab {
    width: 80%;
    height: auto;
  }
}

@media only screen and (max-width: 1024px) {
  /* For mobile phones: */
  .school-lab-container {
    min-height: calc(100vh - 8.1rem);
  }
  .school-lab {
    width: 100vw;
    min-height: 65vh;
    padding: 1rem;
  }
}
