footer {
  box-sizing: border-box;
  height: 3rem;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d5098e;
  > h4 {
    font-weight: light;

    color: whitesmoke;
  }
}

@media only screen and(max-width:880px) {
  footer {
    height: 2rem;
    > h4 {
      font-size: 0.8rem;
    }
  }
}
