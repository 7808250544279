$primary: #5a319b;
$secondary: #d5098e;

//header area
.header {
  height: 6rem;
  display: flex;
  justify-content: space-around;

  .inq {
    margin-top: 0.8rem;
    width: 6rem;
    height: 5rem;
  }
  .logo {
    height: 6rem;
    width: auto;
  }
}

//hamburger icon
.fas.fa-bars {
  display: none;
  position: absolute;
  cursor: pointer;
  top: 4.1rem;
  right: 1rem;
  z-index: 101;
  color: whitesmoke;
  font-size: 1.5rem;
}
//close menu icon
.fas.fa-times {
  display: none;
  position: absolute;
  cursor: pointer;
  top: 4.1rem;
  right: 1rem;
  z-index: 101;
  color: whitesmoke;
  font-size: 1.5rem;
}

//main-menu
.main-menu {
  position: relative;
  display: flex;
  width: 100%;
  height: 2.5rem;
  font-family: Montserrat;
  font-size: 0.8rem;
  background: $secondary;
  justify-content: center;
  list-style-type: none;
  > li {
    line-height: 2.5rem;
    &:hover {
      background-color: $primary;
    }
    a {
      color: whitesmoke;
      line-height: 2.5rem;
      text-decoration: none;
      margin-left: 0.7rem;
      margin-right: 0.7rem;
      &:hover {
        color: orange;
      }
    }
  }
}

.sub-menu {
  display: flex;
  width: 13rem;
  flex-direction: column;
  list-style: none;
  position: absolute;
  top: 8rem;
  z-index: 100;
  background: $primary;
  border-top: 3px solid orange;
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s ease;
  &::before {
    content: '';
    position: absolute;
    top: -1rem;
    left: 2rem;
    border: 0.5rem solid transparent;
    border-bottom-color: orange;
  }

  > li {
    width: 100%;
    line-height: 2.5rem;
    &:hover {
      background-color: rgba($color: $secondary, $alpha: 0.3);
    }
  }
}

.main-menu > li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 2.5rem;
}

.main-menu .fas.fa-angle-down {
  padding-left: 0.8rem;
}

//media query strat here..
@media screen and (max-width: 880px) {
  //hedaer section
  .header {
    height: 3.6rem;
    > .logo {
      height: 3.5rem;
    }
    > .inq {
      display: none;
    }
  }

  //hamburger menu
  .fas.fa-bars {
    display: block;
  }

  .menu-icon.open > .fas.fa-times {
    display: block;
  }
  .menu-icon.open > .fas.fa-bars {
    display: none;
  }

  //main menu section
  .main-menu {
    background-color: $secondary;
    transition: all 0.5s ease;

    > li {
      visibility: hidden;
      opacity: 0;
    }
  }
  .main-menu.open {
    background: $secondary; /* fallback for old browsers */
    position: fixed;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    justify-content: flex-start;
    padding-left: 1rem;
    z-index: 100;
    > li {
      visibility: visible;
      opacity: 1;
    }
  }

  //sub menu section
  .sub-menu {
    position: initial;
    width: 100%;
    max-height: 0;
    border: none;
    border-left: 2px solid orange;
    &::before {
      display: none;
    }
  }

  .main-menu > li:hover > .sub-menu {
    opacity: 1;
    visibility: visible;
    max-height: initial;
  }
}
