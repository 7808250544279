$primary: #5a319b;
$secondary: #d5098e;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:ital,wght@1,300&display=swap');

.principal-message {
  img {
    width: 40vw;
    height: auto;
    margin-top: 1.5rem auto;
  }
  > h2 {
    margin-top: 1rem;
    font-size: 2rem;
    color: $secondary;
    border-bottom: 2px solid gray;
  }
  font-family: 'Open Sans Condensed', sans-serif;
  margin-top: 0;
  width: 60vw;
  padding: 20px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > p {
    font-size: 1.3rem;
    margin: 10px;
    align-self: flex-start;

    text-align: left;
  }
}
@media only screen and(max-width:880px) {
  .principal-message {
    width: 100%;
    > img {
      width: 100%;
    }
    > h2 {
      font-size: 1.5rem;
      margin-top: 1rem;
    }
    > p {
      font-size: 1rem;
    }
  }
}
