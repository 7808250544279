$primary: #5a319b;
$secondary: #d5098e;
.mandatory-container {
  min-width: 50vw;
  max-width: 700px;
  margin: 0 auto;
  height: auto;
  padding-top: 1.5rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
  > h2 {
    margin-bottom: 1rem;
  }
  table th,
  td {
    border: 1px solid $primary;
    padding: 5px;
    line-height: 1.2rem;
    font-size: 0.9rem;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    td {
      &:first-child {
        font-weight: 500;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .mandatory-container {
    padding: 0.5rem;
  }
}
