$primary: #5a319b;
$secondary: #d5098e;

.contact-info-container {
  display: flex;
  justify-content: center;
  min-height: 72vh;
}
.contact-info {
  position: relative;
  top: 2rem;
  width: 50vw;
  height: 50vh;
  padding: 2rem;
  line-height: 2rem;
  h3 {
    color: $secondary;
    text-align: center;
    font-weight: bold;
    font-family: Roboto;
    font-size: 2.2rem;
    padding: 1.5rem;
  }
}

@media only screen and (max-width: 1024px) {
  /* For mobile phones: */
  .contact-info-container {
    min-height: calc(100vh - 8.1rem);
  }
  .contact-info {
    width: 100vw;
    min-height: 65vh;
    padding: 1rem;
    .info__table,
    th,
    td {
      border: 1px solid $primary;
      padding: 5px;
      line-height: 1.2rem;
      font-size: 0.9rem;
    }
    .info__table {
      border-collapse: collapse;
    }

    > h3 {
      line-height: 2.5rem;
      font-size: 1.5rem;
      margin: 1rem 0;
    }
  }
}
